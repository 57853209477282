<template>
  <v-app id="dashboard-view">
    <core-toolbar style="heigth: 50px; padding: 0px 0px 0px;" app />

    <core-drawer app/>
    <v-main >
      <v-fade-transition mode="out-in">
        <router-view />
      </v-fade-transition>
    </v-main>
  </v-app>
</template>

<script>
import coreDrawer from "../components/core/Drawer";
import coreToolbar from "../components/core/Toolbar";

export default {
  name: "DashboardView",
  components: {
    coreDrawer,
    coreToolbar,
  },
  metaInfo() {
    return {
      title: "Ficha Digital",
    };
  },
};
</script>

<style>
</style>
